import React, { useContext } from 'react';

import { CheckoutContext } from './context';

import { Regular, TitleColour } from '../../utils/variables';
import '../Form/fields/checkbox.css';

import loadComponents from '../Loadable';
import { HeadingPlaceholder } from './placeholders';
import { depositAmount } from './calculations';

const H5 = loadComponents('h5');

export default function CheckoutTotals() {
  const { checkout, toggleDeposit } = useContext(CheckoutContext);
  const depositLabel = `Pay a ${depositAmount}% deposit today and the rest when your order is delivered`;
  return (
    <div style={{ marginTop: `2.8125rem` }}>
      <H5
        style={{ textTransform: `uppercase` }}
        fallback={<HeadingPlaceholder />}
      >
        Order <span>Total</span>
      </H5>
      <ul>
        {checkout.totals.map(
          (item, i) =>
            !item.hidden && (
              <li
                key={item.label}
                style={{
                  ...liStyle,
                  marginTop: i !== 0 && `0.9375rem`,
                }}
              >
                <span>{item.label}</span>
                <strong style={strongStyle}>
                  {item.label === 'Discount' && `-`}&pound;
                  {item.amount.toFixed(2)}
                </strong>
              </li>
            ),
        )}
      </ul>
      <div
        style={{
          marginTop: `15px`,
          paddingTop: `15px`,
          borderTop: `1px dotted rgba(0,0,0,0.2)`,
        }}
      >
        <label
          htmlFor="depositPayment"
          className={`checkbox${checkout.deposit ? ' active' : ''}`}
          style={{
            display: `flex`,
          }}
        >
          <input
            id="depositPayment"
            onChange={() => toggleDeposit()}
            value={checkout.deposit}
            type="checkbox"
            aria-label={depositLabel}
            style={{
              display: `none`,
              width: `auto`,
            }}
          />
          <span
            className="label"
            style={{
              ...labelStyle,
              fontWeight: Regular,
              // marginLeft: `0.9375rem`,
            }}
          >
            {depositLabel}
          </span>
        </label>
      </div>
    </div>
  );
}

const liStyle = {
  display: `flex`,
  justifyContent: `space-between`,
  alignItems: `center`,
};

const strongStyle = {
  fontSize: `1.25rem`,
  color: TitleColour,
};

const labelStyle = {
  fontSize: `1rem`,
  // lineHeight: 1,
};
